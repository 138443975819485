@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Black Italic"), local("FiraSans-BlackItalic"),
		url("src/fira-sans/FiraSans-BlackItalic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-BlackItalic.woff") format("woff");
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Bold"), local("FiraSans-Bold"),
		url("src/fira-sans/FiraSans-Bold.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Black"), local("FiraSans-Black"),
		url("src/fira-sans/FiraSans-Black.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Bold Italic"), local("FiraSans-BoldItalic"),
		url("src/fira-sans/FiraSans-BoldItalic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-BoldItalic.woff") format("woff");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans ExtraBold"), local("FiraSans-ExtraBold"),
		url("src/fira-sans/FiraSans-ExtraBold.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-ExtraBold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans SemiBold"), local("FiraSans-SemiBold"),
		url("src/fira-sans/FiraSans-SemiBold.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Italic"), local("FiraSans-Italic"),
		url("src/fira-sans/FiraSans-Italic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-Italic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans ExtraLight"), local("FiraSans-ExtraLight"),
		url("src/fira-sans/FiraSans-ExtraLight.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-ExtraLight.woff") format("woff");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"),
		url("src/fira-sans/FiraSans-MediumItalic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-MediumItalic.woff") format("woff");
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"),
		url("src/fira-sans/FiraSans-LightItalic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-LightItalic.woff") format("woff");
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans ExtraBold Italic"), local("FiraSans-ExtraBoldItalic"),
		url("src/fira-sans/FiraSans-ExtraBoldItalic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-ExtraBoldItalic.woff") format("woff");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Regular"), local("FiraSans-Regular"),
		url("src/fira-sans/FiraSans-Regular.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Medium"), local("FiraSans-Medium"),
		url("src/fira-sans/FiraSans-Medium.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Light"), local("FiraSans-Light"),
		url("src/fira-sans/FiraSans-Light.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans ExtraLight Italic"), local("FiraSans-ExtraLightItalic"),
		url("src/fira-sans/FiraSans-ExtraLightItalic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-ExtraLightItalic.woff") format("woff");
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans SemiBold Italic"), local("FiraSans-SemiBoldItalic"),
		url("src/fira-sans/FiraSans-SemiBoldItalic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-SemiBoldItalic.woff") format("woff");
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Thin Italic"), local("FiraSans-ThinItalic"),
		url("src/fira-sans/FiraSans-ThinItalic.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-ThinItalic.woff") format("woff");
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Fira Sans";
	src: local("Fira Sans Thin"), local("FiraSans-Thin"),
		url("src/fira-sans/FiraSans-Thin.woff2") format("woff2"),
		url("src/fira-sans/FiraSans-Thin.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}
